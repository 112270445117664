@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  
  background-color: white;
 
}
@font-face {
  font-family: DMSans-Bold;
  src: url("./assets/fontfamily/DMSans-Bold.ttf");
}
@font-face {
  font-family: DMSans-Medium;
  src: url("./assets/fontfamily/DMSans-Medium.ttf");
}
@font-face {
  font-family: DMSans-Regular;
  src: url("./assets/fontfamily/DMSans-Regular.ttf");
}
@font-face {
  font-family: Barlow-Bold;
  src: url("./assets/fontfamily/Barlow-Bold.ttf");
}
@font-face {
  font-family: Barlow-Medium;
  src: url("./assets/fontfamily/Barlow-Medium.ttf");
}
@font-face {
  font-family: Barlow-Regular;
  src: url("./assets/fontfamily/Barlow-Regular.ttf");
}
.f-f-b{
  font-family: DMSans-Bold;
}
.f-f-r{
  font-family: DMSans-Regular;
}
.f-f-m{
  font-family: DMSans-Medium;
}
.f-f-b-b{
  font-family: Barlow-Bold;
}
.f-f-b-m{
  font-family: Barlow-Medium;
}
.f-f-b-r{
  font-family: Barlow-Regular;
  
}
@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1100px;
}
}

.ble-one{
  background: none rgb(30, 155, 255);
  width: 35px;
  height: 35px;
  border-radius: 5px;
  box-shadow: rgb(30 155 255 / 40%) 4px 4px 4px 0px;
}
.ble-two{
  width: 35px;
  height: 35px;
  background-color: rgb(250, 138, 140);
  border-radius: 5px;
  box-shadow: rgb(250 138 140 / 40%) 4px 4px 4px 0px;
}
.yopinio{
  background: linear-gradient(45deg, rgba(30, 155, 255, 0.1) 0%, rgba(30, 155, 255, 0.1) 50%, rgba(250, 138, 140, 0.1) 100%);
}
.slider-setting .slick-next{
top: 8%;
right: -40px;
height: 40px;
width: 40px;
}
.slider-setting .slick-prev{
  left: unset;
  top: 25%;
  right: -40px;
  height: 40px;
width: 40px;
  }
  @media only screen and (max-width: 900px) {
    
          .slider-setting .slick-prev{
              top: 18%;
  
              }
    }
  @media only screen and (max-width: 600px) {
      .slider-setting .slick-next{
          top: -10%;
          right: -32px;
          }
          .slider-setting .slick-prev{
              left: unset;
              top: 0%;
              right: -32px;
              }
    }
